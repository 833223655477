import { COUNTRY_CODE } from '@interfaces/graphql/API';

/**
 * Convert country code in nextjs config into country code format
 *
 * @param {string} value - Country code in next.config.js
 * @returns {COUNTRY_CODE} - Formatted country code.
 */

const formatCountryCodeRsl = (value: string | undefined): COUNTRY_CODE => {
    if (!value) {
        return COUNTRY_CODE.SG;
    }

    const country_code = value.split('-')[1].toUpperCase() as COUNTRY_CODE;
    return country_code;
};

export default formatCountryCodeRsl;
