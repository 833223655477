//types
import {
    UseControllersProps,
    UseControllersReturn,
    UseMainCtrlReturn
} from '@components/lib/module/layout/signOut/interfaces/app/controllers/useControllers';

/**
 * The controller
 *
 * @param {UseControllersProps} props - The props.
 * @returns {UseControllersReturn} - The controllers.
 */
const useControllers = ({
    model: { signOutModel },
    lib: {
        external: { router }
    }
}: UseControllersProps): UseControllersReturn => {
    const { signOutStep } = signOutModel;
    /**
     * The controller of Main module
     *
     * @returns {UseMainCtrlReturn} - The sign out function.
     */
    const useMainCtrl = (): UseMainCtrlReturn => {
        /** Sign out the user */
        const signOut = async () => {
            await signOutStep();
            router.reload();
        };

        return { signOut };
    };

    return { useMainCtrl };
};

export default useControllers;
