//type
import { MenuConfig } from '@components/lib/module/layout/menu/interfaces/Menu';

//library
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Menu
 *
 * @param {MenuConfig} config - The config.
 * @returns {ReactElement} - The site menu.
 */
const Main: React.FC<MenuConfig> = (config: MenuConfig): ReactElement => {
    const {
        module: {
            layout: { SignOut }
        },
        external: { Image, Link }
    } = config.lib;

    const { current, currentSubMenu } = config.data;

    const { menu } = config.controller.useMainCtrl();

    const { t } = useTranslation();

    return (
        <div className="sticky top-0 z-30 bg-white h-screen flex text-xs xl:text-base">
            <div className="sticky top-0 z-30 w-9 xl:w-14">
                <div className="h-full flex flex-col text-center items-center pt-3">
                    <div>
                        <div className="flex flex-col gap-y-2">
                            <Link className="order-1" href="/">
                                <a className="w-15">
                                    <Image
                                        aria-label="company-logo"
                                        src="/favicon.ico"
                                        alt="GetSolar"
                                        width="38"
                                        height="38"
                                    />
                                </a>
                            </Link>
                            <Image
                                src={`/${t('flag')}`}
                                height={32}
                                width={32}
                                alt="Country flag"
                            />
                        </div>

                        <div className="mt-6 flex [writing-mode:vertical-rl] rotate-180">
                            {Object.values(menu).map((item) => (
                                <Link key={item.value} href={item.url}>
                                    <a
                                        aria-label={item.label}
                                        className={`p-2 mt-4 transition duration-150 ease-in-out focus:outline-none rounded-lg ${
                                            item.textColor
                                        } ${current !== item.value && 'opacity-40'}`}>
                                        {item.label}
                                    </a>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="mt-auto mb-2">
                        <SignOut lib={config.lib} />
                    </div>
                </div>
            </div>

            {current && menu[current]?.subMenu && (
                <div
                    aria-label="sub-menu"
                    className="w-12 flex justify-end items-center text-center  align-middle [writing-mode:vertical-rl] rotate-180 pb-6">
                    {menu[current].subMenu?.map((item) => (
                        <Link key={item.value} href={item.url}>
                            <a
                                aria-label={item.label}
                                className={`p-2 mt-4 transition duration-150 ease-in-out focus:outline-none rounded-lg ${
                                    item.textColor
                                } ${currentSubMenu !== item.value && 'opacity-40'}`}>
                                {item.label}
                            </a>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Main;
