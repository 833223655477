//types
import { SignOutConfig } from '@components/lib/module/layout/signOut/interfaces/SignOut';

//library
import React, { ReactElement } from 'react';

/**
 * Document
 *
 * @param {SignOutConfig} config - The config.
 * @returns {ReactElement} - The document.
 */
const Main: React.FC<SignOutConfig> = ({
    controller,
    lib: {
        external: { Image }
    }
}: SignOutConfig): ReactElement => {
    const { signOut } = controller.useMainCtrl();

    return (
        <button
            aria-label="sign-out"
            onClick={signOut}
            className="flex p-1 mt-1 rounded-full hover:bg-gray-100 transition duration-150 ease-in-out"
            role="menuitem">
            <Image
                src="/logout.svg"
                width={32}
                height={32}
                alt="sign out button"
                className="scale-x-[-1]"
            />
        </button>
    );
};

export default Main;
