/* tslint:disable */
/* eslint-disable */
// This file extends the amplify-generated API file
// and customizes the type according to the fields called

import {
    CONTACT_METHOD,
    Vendee,
    GetDashboardActivitiesQuery,
    GetDashboardActivityDurationsQuery
} from '@interfaces/graphql/generated/API';

export * from '@interfaces/graphql/generated/API';

// export explicitly to override
export type ListProspectEpcQuery = {
    listEpc: Array<{
        __typename: 'EpcObject';
        label: string;
        value: string;
    }>;
    listVendees: {
        __typename: 'ListVendees';
        total: number;
        vendees: Array<Vendee>;
    };
};

export type GetUserProfileAndEpcQueryVariables = {
    id: string;
};

export type GetUserProfileAndEpcQuery = {
    getProfile?: {
        __typename: 'Profile';
        id: string;
        email: string;
        name: string;
        phone?: string | null;
        contact_method?: Array<CONTACT_METHOD> | null;
        family_name: string;
        company?: string | null;
        group: Array<string>;
        created: string;
        prospects?: Array<string | null> | null;
    } | null;
    listEpc: Array<{
        label: string;
        value: string;
    }>;
};

export enum LOST_REASON {
    PRICING = 'PRICING',
    POOR_FOLLOW_UP = 'POOR_FOLLOW_UP',
    FEATURES_WARRANTIES = 'FEATURES_WARRANTIES',
    NO_BUDGET = 'NO_BUDGET',
    UNSUITABLE_ROOF = 'UNSUITABLE_ROOF',
    SAID_NO = 'SAID_NO',
    OTHERS = 'OTHERS'
}

export type GetDashboardActivitiesAndDurationsQuery = GetDashboardActivitiesQuery &
    GetDashboardActivityDurationsQuery;
