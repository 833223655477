//type
import { MenuProps, MenuConfig } from '@components/lib/module/layout/menu/interfaces/Menu';

//library
import React, { ReactElement } from 'react';

//controller
import useControllers from '@components/lib/module/layout/menu/app/controllers/useControllers';

//code
import Main from '@components/lib/module/layout/menu/app/view/Main';

/**
 * The menu to navigate between pages
 *
 * @param {MenuProps} props - The props
 * @returns {ReactElement} - The filter input.
 */
const Menu: React.FC<MenuProps> = (props: MenuProps): ReactElement => {
    const config: MenuConfig = {
        ...props,
        controller: useControllers()
    };

    return <Main {...config} />;
};

export default Menu;
