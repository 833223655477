//types
import {
    SignOutProps,
    SignOutConfig
} from '@components/lib/module/layout/signOut/interfaces/SignOut';

//library
import React, { ReactElement } from 'react';

//model
import signOutModel from '@components/lib/module/layout/signOut/app/model/signOutModel';

//controller
import useControllers from '@components/lib/module/layout/signOut/app/controllers/useControllers';

//code
import Main from '@components/lib/module/layout/signOut/app/view/Main';

/**
 * Sign out button
 *
 * @param {SignOutProps} props - The props
 * @returns {ReactElement} - Sign out button.
 */
const SignOut: React.FC<SignOutProps> = (props: SignOutProps): ReactElement => {
    const config: SignOutConfig = {
        ...props,
        controller: useControllers({ ...props, model: { signOutModel: signOutModel(props) } })
    };

    return <Main {...config} />;
};

export default SignOut;
