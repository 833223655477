//type
import {
    UseControllersReturn,
    UseMainCtrlReturn
} from '@components/lib/module/layout/menu/interfaces/app/controllers/useControllers';
import { Menu } from '@components/lib/module/layout/menu/interfaces/Menu';

/**
 * The controller.
 *
 * @returns {UseControllersReturn} - The controllers.
 */
const useControllers = (): UseControllersReturn => {
    /**
     * The controller for Main module.
     *
     * @returns {UseMainCtrlReturn} - The menu data.
     */
    const useMainCtrl = (): UseMainCtrlReturn => {
        const menu: Menu = {
            ['USER']: {
                label: 'Users',
                value: 'USER',
                url: '/users',
                textColor: 'text-safety-orange',
                subMenu: [
                    {
                        label: 'Add',
                        value: 'ADD_USER',
                        url: '/users/create',
                        textColor: 'text-safety-orange'
                    }
                ]
            },
            ['CUSTOMER']: {
                label: 'Customers',
                value: 'CUSTOMER',
                url: '/customers?sort_field=INTERNAL_ID&sort=ASC',
                textColor: 'text-lime',
                subMenu: [
                    {
                        label: 'Bills',
                        value: 'BILLS',
                        url: '/customers/bills',
                        textColor: 'text-lime-90'
                    },
                    {
                        label: 'Milestones',
                        value: 'MILESTONES',
                        url: '/customers/milestones',
                        textColor: 'text-lime-90'
                    },
                    {
                        label: 'Add',
                        value: 'ADD_CUSTOMER',
                        url: '/details/create',
                        textColor: 'text-lime-90'
                    }
                ]
            },
            ['PROSPECT']: {
                label: 'Prospects',
                value: 'PROSPECT',
                url: '/prospects?status=CONNECTED&status=PROPOSAL_PENDING&status=PROPOSAL_SAVED&status=PROPOSAL_SENT&status=ADVISORY_CALL_COMPLETED&status=SITE_VISIT_ARRANGED&status=SITE_VISIT_COMPLETE&status=REVISIT&status=REVISIT_LOW_PRIORITY&sort_field=UPDATED&sort=DESC',
                textColor: 'text-lavender',
                subMenu: [
                    {
                        label: 'EPCs',
                        value: 'EPC',
                        url: '/epc/',
                        textColor: 'text-lavender-90'
                    },
                    {
                        label: 'Add',
                        value: 'ADD_PROSPECT',
                        url: '/details/create',
                        textColor: 'text-lavender-90'
                    }
                ]
            },
            ['LEAD']: {
                label: 'Leads',
                value: 'LEAD',
                url: '/leads/?building_type=LANDED_HOUSE&building_type=CONDOMINIUM&building_type=HDB_FLAT&building_type=RENTED_COMMERCIAL_PROPERTY&building_type=OWNED_COMMERCIAL_PROPERTY&validated=PENDING&validated=VALIDATED&size=100',
                textColor: 'text-cyan'
            },
            ['DASHBOARD']: {
                label: 'Dashboard',
                value: 'DASHBOARD',
                url: '/',
                textColor: 'text-rto-simulator',
                subMenu: [
                    {
                        label: 'Activities',
                        value: 'ACTIVITIES',
                        url: '/',
                        textColor: 'text-rto-simulator-90'
                    },
                    {
                        label: 'Apportions',
                        value: 'APPORTIONS',
                        url: '/dashboard/apportions',
                        textColor: 'text-rto-simulator-90'
                    }
                ]
            }
        };

        return { menu };
    };

    return { useMainCtrl };
};

export default useControllers;
