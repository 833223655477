//type
import { SignOutProps } from '@components/lib/module/layout/signOut/interfaces/SignOut';
import { SignOutModelReturn } from '@components/lib/module/layout/signOut/interfaces/app/model/signOutModel';

/**
 * Sign Out Model
 *
 * @param {SignOutProps} props - The Auth library from aws-amplify.
 * @returns {SignOutModelReturn} - The model functions.
 */
const signOutModel = ({
    lib: {
        external: { Auth }
    }
}: SignOutProps): SignOutModelReturn => {
    /**
     * Signs out with amplify
     *
     * @returns {Promise<any>} - The promise resolved if successfully signed out.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const signOutStep = async (): Promise<any> => {
        return await Auth.signOut();
    };

    return { signOutStep };
};

export default signOutModel;
